.wrapper {
  width: 500px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.arrayProducts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}
