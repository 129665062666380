.wrapper {
    width: 1252px;
    height: 191px;
    background-color: #67b3ff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 14px;
    display: flex;
    align-items: flex-end;
    padding: 30px;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &_title {
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        color: #ffffff;
        margin: 0;
    }

    &_button {
        padding: 9px 16px;
        background: #ffffff;
        border-radius: 8px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        border: 0;
        color: #031b4e;
    }
}
